@import '@spscommerce/colors/dist/_colors.scss';
@import '~react-placeholder/lib/reactPlaceholder.css';

.sps-placeholders__container {
  position: relative;
  &.show-loading-animation {
    .inner {
      animation: react-placeholder-pulse 1.5s infinite;
    }
  }
  &.service-row {
    &.inline-service {
      padding: 0 15px;
      .service-link {
        width: 75%;
      }
      &.toggle {
        padding: 15px;
        .service-link {
          width: 90%;
        }
      }
      .text-row {
        margin-top: 5px;
      }
    }
    .text-row {
      margin-top: !important 0;
    }
  }
}
.app-card--small-placeholder {
  margin-top: 20px;
  .sps-card__body {
    padding: 12px 20px;
  }
  .sps-placeholders__container.service-row.inline-service {
    padding: 0;
  }
}
.sps-placeholders__container--inner {
  padding: 10px 20px;
}
.sps-placeholders__overlay--fade-out {
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  z-index: 100;
  background: linear-gradient(to top, $gray100 0%,rgba(255,255,255,0) 100%);
}
.sps-placeholders__media-icon {
  background-color: rgb(224, 224, 224);
  border-radius: 500rem;
  width: 55px;
  height: 100%;
  min-height: 55px;
  min-width: 55px;
  margin-right: 10px;
}


// Tiered Placeholder
.sps-placeholders__tiered-item{
  width: 100%;
  height: 1em;
  background: $gray300;
  margin-bottom: 10px;
    &--child {
      width: calc(100% - 20px);
      margin-left: 20px;
    }
    &--grandchild {
      width: calc(100% - 40px);
      margin-left: 40px;
    }
    &:last-child{
      margin-bottom: 0;
    }
}

// MarkDown Placeholder
.sps-placeholders__markdown-item{
  width: 100%;
  height: .75rem;
  background: $gray300;
  margin-bottom: .5625rem;
    &--first {
      margin-bottom: 1.125rem;
    }
    &--sequencefirst {
      width: calc(100% - 2.25rem);
      margin-right: 2.25rem;
    }
    &--last {
      width: 30%;
      margin-bottom: 1.5625rem;
    }
    &:last-child{
      margin-bottom: 0;
    }
}
