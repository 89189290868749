.app-list.row {
  margin-bottom: 0;
}
.app-list {
  .row-wrapper {
    display: flex;
    justify-content: space-between;
    .icon-card {
      &:first-child {
        margin-right: 20px;
      }
      &:last-child {
        margin-left: 20px;
      }
    }
    &.column3 {
      .create-app-card {
        max-width: 254px;
        width: 100%;
        margin-left: 20px;
      }

    }
    &.column2 {
      .create-app-card {
        max-width: 532px;
        width: 100%;
      }
    }
    &.column1 {
      .create-app-card {
        width: 100%;
      }
    }
  }
}
