@import '~@spscommerce/ds-styles/index.css'; // Flag
@import '@spscommerce/colors/dist/_colors.scss';

.app-edit-page {

  .app-edit-header {
    .back-to-apps {
      color: $blue400;
      text-transform: uppercase;
    }
  }

  .app-side-bar__bordered-list-item {
    .app-side-bar__text {
      font-weight: 400;
    }
  }

  .app-save-button-container {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
  }

  .blue200.app-side-bar-icon.sps-icon {
    color: $blue200;
  }

  .blue400.app-side-bar-icon.sps-icon {
    color: $blue400;
  }

  .purple200.app-side-bar-icon.sps-icon {
    color: $purple200;
  }

  .orange200.app-side-bar-icon.sps-icon {
    color: $orange200;
  }

  .gray500.app-side-bar-icon.sps-icon {
    color: $gray500;
  }

  .green300.app-side-bar-icon.sps-icon {
    color: $green300;
  }

  .gray-next-box {
    &.app-type {
      margin-bottom: 10px;
    }
    & .sps-card {
      border: 1px solid $gray100;
      background-color: $gray100;
      height: 100%;
      display:flex;

    }
    p, h4 {
      margin: 0;
      line-height: 28px;
    }
    h4 {
      font-size: 14px;
      font-weight: 600;
    }
  }
}
