@import "@spscommerce/colors/dist/_colors.scss";
$iconBoxWidth: 106px;
$iconBoxHeight: 78px;
.iconSelectorWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.iconSelectorContainer {
  position: relative;
  overflow: hidden;
  border: 1px solid $gray300;

}
.iconSelector {
  position: absolute;
  display: flex;
  transition: left 0.75s, right 0.75s cubic-bezier(0.075, 0.82, 0.165, 1);
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  .icon {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: $iconBoxWidth;
    // height: ($iconBoxHeight - 2);
    text-align: center;
    border-bottom: 1px solid $gray300;
    border-right: 1px solid $gray300;
    border-top: 1px transparent solid;
    border-left: 0 none;
    height: $iconBoxHeight;
    line-height: 106px;
    background: $white;
    cursor: pointer;
    &:focus {
      outline: 0 none;
      border: 1px solid $blue400;
    }
    &.active {
      outline: 0;
      background: $blue100;
      border: 1px solid $blue400;
      height: ($iconBoxHeight - 2);
    }
    i {
      font-size: 48px;
      line-height: 74px;
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
.nav-buttons {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 30px;
  width: 30px;
  border: 0 none;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  border-radius: .1875rem;
  line-height: 2.75;
  background: $white;
  &[disabled] i {
    color: $gray200;
  }
  &:focus, &:active, &:focus-within {
    outline: 0 none;
  }
  &[disabled]:hover {
    background: transparent;
    i {
      color: $gray200;
    }
  }
  &:hover, &:active, &:focus {
    background: $gray300;
    i {
      color: $gray500;
    }
  }


  i {
    color: #d2d4d5;
    font-size: 16px;
    line-height: 16px;
  }
}
.color-picker {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 120px;
  margin: 16px auto 0;
  .selector {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0 none;
    width: 12px;
    height: 14px;
    cursor: pointer;
  }
  .blue200 {
    background: $blue200;
  }
  .blue400 {
    background: $blue400;
  }
  .purple200 {
    background: $purple200;
  }
  .orange200 {
    background: $orange200;
  }
  .gray500 {
    background: $gray500;
  }
  .green300 {
    background: $green300;
  }
}
.blue200.icon i {
  color: $blue200;
}
.blue400.icon i {
  color: $blue400;
}
.purple200.icon i {
  color: $purple200;
}
.orange200.icon i {
  color: $orange200;
}
.gray500.icon i {
  color: $gray500;
}
.green300.icon i {
  color: $green300;
}
