@import "@spscommerce/colors/dist/_colors.scss";

.col-six-wrapper {
  .circle {
    background: $gray100;
    border-radius: 100%;
    height: 5.625rem;
    width: 5.625rem;
    i {
      font-size: 3rem;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
  .col-six-body {
    padding-left: 1.25rem;
    h3 {
      margin-bottom: 0;
    }
  }
  .col-six-link {
    margin-right: 1.25rem;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.125rem;
  }
}
