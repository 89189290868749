@import '@spscommerce/colors/dist/_colors.scss';

.sps-workflow{
  .sps-workflow__steps__wrapper{
    background: $gray100;
    border: {
      color: $gray300;
      style: solid;
      top: 0rem;
      width: .0625rem;
      bottom-left-radius: .1875rem;
      bottom-right-radius: .1875rem;
    }
    padding: 0.625rem;
  }

  .sps-workflow__step-heading {
    color: $gray600;
    font-size: .875rem;
    font-weight: 600;
    margin-bottom: 0;
  }

  .sps-workflow__heading {
    color: $gray600;
    font: {
      size: 0.75rem;
      weight: 600;
    }
    margin-bottom:  0rem;
  }

  .sps-workflow__step.active{ 
      i{
        background-color: $purple100;
        border: 1px solid $purple200;
        &::before{
          color:$purple200;
        }
      }
  }
}
