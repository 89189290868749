.trial-user-banner {
    position: fixed;
    top: 6.5625rem;
    z-index: 1030;
    background-color: #d9f0fa;

    &.trial-user-banner__show-ie-banner {
        top: 11.6875rem;
    }
}
