@import "@spscommerce/colors/dist/_colors.scss";

.location-page-content__card {
  .sps-card__header {
    border-bottom: 0;
  }
  .sps-card__title {
    font-weight: 300;
    font-size: 24px;
    text-transform: capitalize;
  }
  .sps-card__body {
    padding: {
      top: 0;
    };
  }
}

.inline-location-card {
  .sps-card__header {
    border-bottom: 0;
  }
  .sps-card__title {
    font-weight: 300;
    font-size: 24px;
    text-transform: capitalize;
  }
  .sps-card.sps-card--collpased {
    .sps-card__body {
      padding: 0;
    }
    &.app-edit-services {
      .sps-card__body {
        padding: 0;
      }
    }
  }
}

.location-row {
  align-items: center;
  border-bottom: 1px solid $gray300;
  display: flex;
  padding: 20px 40px 20px 12px;
  &:last-child {
    border-bottom: 0;
  }
  .sps-toggle {
    float: right;
  }
  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $gray200;
    border-radius: 100%;
    height: 40px;
    width: 40px;
  }
  .circle-icon {
    font-size: 16px;
    color: $gray500;
  }
  .location-link {
    margin-left: 15px;
    .location-name {
      color: $gray500;
      font-size: 14px;
      font-weight: 600;
      margin: 0;
    }
  }
  .location-email a {
    color: $blue400;
    font-size: 14px;
    font-weight: 600;

    &:hover {
      text-decoration: none;
      color: $orange300;
    }
  }
}

.location-address {
  .location-address-card {
    margin-top: 0;
  }
  &-card {
    display: inline-flex;
    justify-content: space-between;

    &__col {
      &:last-child,
      &:only-child {
        margin-bottom: 0;
      }

      display: flex;
      flex: 1;
      flex-direction: column;
      font-size: 14px;
    }

    &__phone {
      margin: 10px 0 0 0;
    }

    &__office-type {
      color: #62686B;
    }
  }
}

.font-weight-600 {
  font-weight: 600;
}

.other-location {
  flex-direction: column;
  margin-bottom: 0;
}

.divider {
  position: relative;
  height: auto;
  margin: {
    top: 0.625rem;
    right: 1.25rem;
    bottom: 0.625rem;
    left: 0.625rem;
  }
}
