@import "sass-rem/rem";
@import "@spscommerce/colors/dist/_colors.scss";

.rsx-ui-page {
    .sps-list-toolbar {
        &__buttons {
            flex: 1 0 auto;

            > div {
                display: flex;
                justify-content: flex-end;

                .sps-select__dropctrl,
                .sps-option-list.sps-option-list--searchable {
                    width: 230px;
                }

                .sps-custom-checkbox {
                    display: inline-block;
                }
            }
        }
    }

    .sps-badge {
        float: right;
    }

    .sps-form-control {
        padding-left: 0.5rem;
    }
}

.breadcrumb {
    position: fixed;
    top: rem(112px);
    padding-left: rem(10px);
    font-size: rem(12px);
    line-height: rem(18px);

    a {
        font-weight: 600;
    }

    i {
        position: relative;
        top: rem(2px);
        margin: rem(2px);
        color: $black;
    }
}

.version-display {
    position: absolute;
    right: rem(20px);
    top: rem(20px);
    font-size: rem(14px);
    font-weight: 600;
    line-height: rem(20px);
}

.sps-docs-markdown {
    margin-bottom: 75px;
}
