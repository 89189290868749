.all-user-banner {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 5px;
    position: fixed;
    top: 6.5625rem;
    z-index: 1030;
    background-color: #fcf1e7;

    &.all-users-banner__show-ie-banner {
        top: 11.6875rem;
    }
}

.closeBtn {
    padding-right: 100px;
}

.message {
    padding-left: 100px;
    margin: 0 auto;
}
