@import "@spscommerce/colors/dist/_colors.scss";

.create-app-card {
  min-height: 245px;
  align-self: stretch;
  padding: 0;
  width: 100%;
  &:hover {
    background: $gray100;
    color: $gray600;
    border-color: $gray300;
    .sps-text-light.create-app-card__text--light {
      color: $gray400;
    }
  }
  & span {
    display: block;
    text-align: center;
  }
  .sps-card__body {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  a:hover {
    text-decoration: none;
  }
  .sps-card__body {
    a {
      width: 100%;
      text-align: center;
      padding: 0 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
    }
  }
}

.create-app-card__icon {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}
.create-app-card {
  margin-bottom: 20px;
}
.create-app-card__container-header {
  margin: 0 auto;
  margin-top: 10px;
}

.create-app-card__text--header {
  font-size: 24px;
  font-weight: 300;
  color: $gray500;
  line-height: 1;
}

.create-app-card__text--light {
  font-size: 14px;
  color: $gray400;
}

.create-app-card__button {
  margin-top: 12px;
}
