@import "sass-rem/rem";
@import "@spscommerce/colors/dist/_colors.scss";

.profile-side-nav {
    .sps-side-nav__section-content {
        &.active {
            border-left: 0.125rem solid $blue200;
        }

        a.sps-side-nav__link {
            align-items: center;
            color: $gray600;
            i {
                font-size: 0.625rem;
                line-height: 1.25rem;
                top: 0.3125rem;
            }
        }

        a.sps-side-nav__link.active {
            font-weight: 600;
            color: $gray600;
        }
    }
}

.sps-employee .sps-form-control {
    border-color: #e8e9e9;
    box-shadow: none;
    color: #e8e9e9;
}
