@import "@spscommerce/colors/dist/_colors.scss";

section {
  margin-bottom: 2.825rem;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 1.125rem;
  }

  .contact-card {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 0.625rem;

    &__body {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
      padding: 1.875rem 0;
      text-align: center;

      &__heading {
        color: #1f282c;
        font-size: 1.5rem;
        font-weight: 300;
        line-height: 1.5rem;
        text-align: center;
      }

      &__sub-heading,
      &__link {
        color: #62686b;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.125rem;
        margin-bottom: 0;
        margin-top: 0.3125rem;

        .phone {
          font-weight: 600;
        }
      }

      &__link > a {
        color: #0077aa;
        font-weight: 600;
        margin-left: 0.125rem;
      }

      .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $gray100;
        border-radius: 100%;
        height: 104px;
        width: 104px;
        margin: 0 auto 0.9375rem;

        .circle-icon {
          font-size: 3.5rem;
          color: $gray500;
        }

        img {
          height: 42px;
          width: 50px;
        }

        .orange-icon {
          color: #ff9632;
        }

        .blue-icon {
          color: #0077aa;
        }
      }

      > div {
        flex: 1;
      }
    }

    .sps-card {
      flex: 0 1 49%;

      &__body {
        padding: 0;
      }
    }
  }
}

.button-capital-heading {
  & > button {
    text-transform: capitalize;
  }
}

.sps-focused-task {
  background-color: #f6f6f6;
}
