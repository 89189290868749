@import "@spscommerce/colors/dist/_colors.scss";
@import "sass-rem/rem";

$spacer: rem(20px);
.workflow-overview {
    display: flex;
    flex-direction: row;
    margin-bottom: rem(10px);

    &__icon-container {
        border: rem(1px) solid $gray300;
        border-radius: 100%;
        height: rem(70px);
        min-width: rem(70px);
        position: relative;

        i {
            font-size: rem(38px);
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .workflow-overview__content-container {
        margin-left: rem(20px);

        h2 {
            color: $gray600;
            font-size: rem(14px);
            font-weight: 600;
            line-height: rem(18px);
            margin-bottom: rem(10px);
        }

        p {
            font-size: rem(14px);
            line-height: rem(18px);
            margin-bottom: rem(10px);
        }

        ul {
            margin: 0 0 ($spacer * 0.5) 0;
            padding-left: rem(20px);
            & > li {
                font-size: rem(14px);
                line-height: rem(28px);
                margin-left: rem(20px);
                &::before {
                    font-weight: bold;
                    padding-right: rem(10px);
                }
                p {
                    display: inline;
                    margin-left: rem(-4px);
                }
                ul {
                    list-style-type: disc;
                    padding-left: rem(0px);
                }
            }
        }
    }
}
