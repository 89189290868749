.sps-description-list__term,
.sps-description-list__definition {
  white-space: pre-line;
}

.label-text--dark {
  color: #1f282c;

}

// Remove this when we have latest styles and bug fixed for list toolbar
.w-100{
  .sps-list-toolbar{
    .sps-input-group {
      div.sps-form-control{
        border-left : 1px solid #a5a9ab;
        border-radius: .1875rem;
      }
      i{
        margin-left: 0px;
      }
    }
  }
  
  .sps-list-toolbar--pinned{
    position: relative;
    top: auto;
    left: 0;
    width: 100%;
    margin-left: 0px;
  }
  
  .sps-list-toolbar--pinned:after{
    background: none;
    width: 0px;
  }
}
