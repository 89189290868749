@import "@spscommerce/colors/dist/_colors.scss";

.sps-wallpaper-ul {
    background-color: $gray100;
    background-repeat: repeat;
}

.sps-login-card-ul {
    margin-top: 40%;
    min-height: 426px;
    position: absolute;
    display: flex;
    justify-self: center;
    align-self: center;
    .sps-card__body {
        display: flex;
        width: 400px;
        padding: 0px;
    }
}

.login-card-ul {
    padding: 40px;
    text-align: center;

    h1 {
        font-weight: 600;
    }
}

.create-user-body-ul {
    text-align: center;
}

.login-content-body__header-ul {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}

.login-content-body__description-ul {
    margin-bottom: 15px;
}

.login-content-body__form-buttons-ul {
    .disabled.sps-btn {
        background-color: transparent;
    }
    .disabled.sps-btn:hover {
        background-color: transparent;
    }

    .btn-confirm,
    .sps-btn--confirm {
        background-color: #007dbe;
    }
    .sps-btn--confirm:hover {
        background-color: #09638d;
    }
}

.login-content-body__form-submit-ul {
    text-align: center;
    display: block;
    margin-top: 20px;
    width: 100%;

    &.disabled.sps-btn {
        background-color: transparent;
    }
    &.disabled.sps-btn:hover {
        background-color: transparent;
    }

    &.btn-confirm,
    &.sps-btn--confirm {
        background-color: #007dbe;
    }
    &.sps-btn--confirm:hover {
        background-color: #09638d;
    }

    button {
        padding: 16px 0 !important;
    }
}

.sps-logo-ul {
    img {
        height: 86px;
        margin: 0 117px;
    }
    margin-bottom: 24px;
}

.account-finalized-ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    min-height: 316px;

    img {
        margin-top: 20px;
        margin-bottom: 5px;
    }
}

.sps-wallpaper {
    background-image: url("https://cdn.prod.spsc.io/web/framework/assets/18.03.02/images/sps-icon-background@2x.png");
    background-size: 200px 200px;
    background-repeat: repeat;
    background-color: #f6f6f6;
}

.sps-login-card {
    margin-top: 40%;
    min-height: 426px;
    position: absolute;
    display: flex;
    justify-self: center;
    align-self: center;
    .sps-card__body {
        display: flex;
        width: 780px;
        padding: 0px;
    }
}

.sps-login-card__top-shelf {
    position: absolute;
    bottom: 100%;
    padding: 20px 0;
}

.sps-login-card__col {
    text-align: center;
    align-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.sps-login-card__col--left {
    background: #1f282c;
    width: 42%;
    min-height: 250px;
    border-radius: 0.1875rem 0 0 0.1875rem;

    .sps-commerce-logo {
        img {
            width: 211px;
            height: auto;
        }
    }

    .dev-center-logo-sub-header {
        margin-top: 7px;
        color: #ffff;
        font-size: 18px;
        font-weight: 600;
    }

    .logo-separator {
        margin-top: 7px;
    }
}

.sps-login-card__col--right {
    width: 58%;
    border-radius: 0 0.1875rem 0.1875rem 0;
    padding: 50px 0;
}

.login-content-body {
    text-align: left;
    width: 296px;
    min-height: 292px;
    label {
        color: #1f282c;
    }
}

.login-content-body,
.authorize-app {
    min-height: 240px;
}

.login-content-body__contact-link {
    font-weight: 600;
}

.login-content-body__header {
    font-size: 24px;
    margin-bottom: 20px;
}

.login-content-body__label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.password-reset-complete {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    min-height: 316px;
    p {
        margin: 0;
        color: #4b5356;
    }
    img {
        margin-top: 20px;
        margin-bottom: 5px;
    }
}

.login-content-body__form-input {
    margin-bottom: 10px;
}

.login-content-body__form-buttons {
    text-align: center;
}

.login-content-body__form-submit {
    display: block;
    margin-top: 20px;
    width: 100%;
}

.login-content-body__form-link {
    &.btn {
        display: block;
        margin-top: 30px;
    }
}

.login-content-body__form-checkbox-label {
    margin-left: 5px;
}

.authorize-app {
    display: flex;
    .sps-custom-control__label {
        color: #1f282c;
        display: inline;
    }
    .sps-btn {
        width: 100%;
        button {
            width: 100%;
        }
    }
    .sps-custom-checkbox {
        margin-right: 0;
        margin-top: 0;
    }
}
.authorize-app__body {
    margin: auto;
    p {
        margin-bottom: 20px;
    }
}
