.sps-footer {
    align-items: center;
    bottom: 0;
    color: #62686b;
    display: flex;
    height: 4.25rem;
    left: 0;
    position: relative;
    right: 0;
    z-index: 0;
    background-color: #f6f6f6;

    &__content {
        align-items: center;
        display: flex;
        margin: auto;
        max-width: 73.75rem;
        min-width: 0;
        flex: 1;
        font-size: 0.875rem;
        line-height: 1.25rem;

        .sps-footer__item:first-child {
            padding-left: 0;
        }

        .sps-footer__item:last-child {
            margin-left: auto;
            padding-right: 0;
        }
    }

    &__item {
        padding: 0.625rem;
        .sps-btn--link {
            color: inherit;
            font-size: inherit;
            font-weight: inherit;
            padding: 0.3125rem 0;
            &:hover {
                color: inherit;
                a {
                    text-decoration: none;
                }
            }
        }
    }

    &__logo {
        height: 1.813rem;
        width: 2.063rem;
    }

    .sps-vertical-rule {
        height: 1.25rem;
    }
}
