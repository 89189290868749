@import "@spscommerce/ds-colors/colors.scss";

.notification-banner {
    overflow: auto;
    padding: 16px;
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    margin: auto;
    height: 82px;
    background: repeating-linear-gradient(
        -40deg,
        #2a373b,
        #2a373b 25px,
        #344044 25px,
        #344044 50px
    );

    .fa-exclamation-triangle {
        &::before {
            color: $orange200;
            font-size: 22px;
        }
    }

    .inner-content {
        padding: 4px;
        max-width: 1180px;
        margin-left: auto;
        margin-right: auto;

        .warning {
            color: $white;
            font-size: 14px;
            padding-left: 8px;
        }
    }
}
