.splash-main-content {
    background-image: url(https://cdn.prod.spsc.io/web/framework/assets/18.03.02/images/sps-icon-background@2x.png);
    background-size: 200px 200px;
    background-repeat: repeat;
    background-color: #f6f6f6;
    text-align: center;
    font-family: "Source Sans Pro";
    position: absolute;
    height: calc(100vh);
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0 100px 0;
}

.sps-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
}

.looking-for-dev-cent {
    display: inline-block;
    height: 140px;
    width: 880px;
    color: #1f282c;
    font-size: 56px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 70px;
    margin-bottom: 20px;
    text-align: center;
}

.the-latest-dev-center {
    width: 880px;
    height: 76px;
    color: #1f282c;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 38px;
}
