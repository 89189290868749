@import "@spscommerce/colors/dist/_colors.scss";
@import "sass-rem/rem";

$gray-transparent: white;
$scroll-shadow-color: rgba(0, 0, 0, 0.15);
$scroll-shadow-color-blur: rgba(0, 0, 0, 0);

:root {
    --space: 9.05rem;
}

.sps-side-nav-card {
    max-height: calc(100vh - var(--offset) * 1.2);
}

.sps-side-nav-card {
    .sps-card__body {
        overflow-y: auto;
        background: /* Shadow covers */ linear-gradient(white 30%, $gray-transparent),
            linear-gradient($gray-transparent, white 70%) 0 100%,
            /* Shadows */ linear-gradient($scroll-shadow-color, $scroll-shadow-color-blur),
            linear-gradient($scroll-shadow-color-blur, $scroll-shadow-color) 0 100%;
        background-repeat: no-repeat;
        background-size: 100% rem(50px), 100% rem(50px), 100% rem(10px), 100% rem(10px);
        background-attachment: local, local, scroll, scroll;
    }
}

.docs-body__sidebar {
    --offset: var(--space);
    flex-grow: 1;
    flex-basis: 300px;
    align-self: start;
    position: sticky;
    top: var(--offset);
    min-height: calc(100vh - var(--offset) * 1.14);
}

.side-nav__section {
    &.tree-active {
        border-left: 1px solid $blue200;
    }
}
.sps-side-nav__section-title {
    color: $gray400;
    font-weight: bold;
    margin-bottom: 0.5rem;
    padding-left: 1.175rem;
    text-transform: uppercase;
}

.side-nav__branch {
    padding: 0;
    list-style: none;
    margin-bottom: 0;
    li {
        position: relative;
    }
    .side-nav__leaf {
        display: inline-block;
        width: 100%;
        cursor: pointer;
    }
}

// For the new authentication section
@for $i from 1 through 4 {
    .pre-expanded {
        .side-nav__branch--#{$i} {
            .side-nav__leaf {
                padding-left: 25px + (20px * $i) -20px;
            }
            .toc-top > span > .side-nav__leaf {
                padding-left: 30px;
            }
            .side-nav__toggle {
                left: 5px + (20px * $i) + 5px - 20px;
                &.closed {
                    left: 7px + (20px * $i) + 5px -20px;
                }
            }
            .toc-top > .side-nav__toggle {
                left: 15px;
                &.closed {
                    left: 15px;
                }
            }
        }
    }
}

@for $i from 1 through 4 {
    .side-nav__branch--#{$i} {
        .side-nav__leaf {
            padding-left: 25px + (20px * $i);
        }
        .side-nav__toggle {
            left: 5px + (20px * $i) + 5px;
            &.closed {
                left: 7px + (20px * $i) + 5px;
            }
        }
    }
}

.side-nav__toggle {
    top: 5px;
    position: absolute;
    i {
        font-size: 8px;
    }
}

// Expiremental SPS Styles
.sps-side-nav {
    .sps-side-nav__section-content {
        a.sps-side-nav__link,
        span.side-nav__leaf,
        .side-nav__leaf--external {
            color: $gray500;
            display: block;
            font-size: 14px;
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
            text-decoration: none;
            &.active {
                font-weight: 500;
                color: $gray500;
                background-color: $blue100;
            }
        }

        a.sps-side-nav__link {
            display: flex;
            justify-content: space-between;

            .sps-badge {
                height: 1.125rem;
                margin-right: 1.25rem;
            }
        }
    }
}

a.sps-side-nav__link--top {
    padding-left: 30px;
    position: relative;
    & > .sps-icon {
        position: absolute;
        left: 15px;
        top: 8px;
        font-size: 8px;
    }
}
