@import "@spscommerce/colors/dist/_colors.scss";
@import "sass-rem/rem";

.card-margin {
    margin-bottom: rem(20px);
}

.text-highlight {
    font-family: "Source Code Pro", monospace;
    background-color: $blue100;
    border-radius: rem(3px);
    padding: rem(3.2px);
}

.copy-code-button {
    &.sps-btn button {
        padding: 0;
    }
}

.dcui-code-snippet {
    font-size: 0.875rem;
}

.sps-content-row__col-group {
    border-radius: rem(3px) !important;
}

.sps-content-row__expansion {
    border-radius: rem(0 0 3px 3px) !important;
}

.section-title {
    display: flex;
    margin-bottom: 0.625rem;
    align-items: center;
    .section-parent {
        font-size: 0.9375rem;
        button {
            font-weight: 600;
            font-size: 16px;
            line-height: 1.5rem;
            &:focus {
                outline: none;
                text-decoration: none;
            }
        }
    }
    .section-icon {
        font-size: 16px;
    }
    .current-section {
        font-size: 16px;
    }
}

.label-tool-type-info {
    font-family: "Source Code Pro", monospace;
    background-color: #d9f0fa;
    border-radius: 3px;
    width: auto;
    margin-bottom: 0.3rem;
    display: table;
    padding: 0.2rem;
    line-height: 20px;
}
