@import '@spscommerce/colors/dist/_colors.scss';
@import "sass-rem/rem";

.home-page-container {
  width: 100%;
  margin-top: 40px;
  &.collapse {
    margin-top: 0;
  }
}

  .home-page-content__main {
    padding-right: 20px;
  }
  .home-page-content__side{

  }

    .home-page-content__container-side-heading {
      display: flex;
    }

    .home-page-content__heading{
      padding: 0;
    }

    .home-page-content__side-app-link{
      align-self: flex-end;
      text-align: right;
      margin-bottom: 10px;
      padding: 0;
      font-weight: 600;
    }

    .home-page__create-app-card {
      .sps-card__body {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }

      .home-page__create-app-card-image-wrapper {
        width: 45%;
      }

        .home-page__create-app-card-image {
          width: 100%;
          height: auto;
        }

      .home-page__create-app-card-text {
        width: 55%;
      }

        .home-page__create-app-card-heading {
          font-weight: 600;
          font-size: 16px;
          text-transform: uppercase;
          margin-bottom: 5px;
          margin-top: 10px;
        }

        .home-page__create-app-card-subheading {
          margin-bottom: 10px;
          color: $gray500;
          line-height: 14px;
        }

        .home-page__create-app-submit-btn {
          width: 50%;
        }

  .home-page-content__heading {
    font-weight: 300;
  }

  .home-page-content__card {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }

    .home-page-content__service-card {
      margin-top: 20px;
      &:first-child {
        margin-top: 0;
      }
    }

    .home-page-content__card-heading {
      font-size: 18px;
    }

    .home-page-content__card-link {
      display: block;
      font-weight: 600;
      margin-top: 10px;
    }

.rsx-card-container{
  display: flex;
  flex-direction: row;

  &__icon-container{
    background-color: $gray100;
    border-radius: 100%;
    height: 4.375rem;
    min-width: 4.375rem;
    margin-right: 20px;
    position: relative;
  }

  i {
    font-size: 2.375rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

}
.home-page__heading {
  font-weight: 300;
  margin-bottom: 1.25rem;
}

.not-loggedin-page {
  h2 {
    font-size: rem(24px);
    font-weight: 300;
    line-height: rem(24px);
  }

  h3 {
    font-size: rem(24px);
    font-weight: 300;
    line-height: rem(24px);
  }

  h4{
    font-size: rem(20px);
    line-height: rem(20px);
    font-weight: 300;
  }

  .service-row-mb {
    margin-bottom: rem(24px);
  }

  .col-9 {
    padding:0px
  }

  img{
    width: 178px;
    height:95px
  }
}

