.container {

  .save-detail {
    .save-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 20px;
    }
  }
}
.row.app-details {
  .sps-form-group {
    .sps-form-control.input--clearable {
      padding-right: 20px;
    }
  }
}
