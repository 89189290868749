.login-content-body {
    .login-content-body__form-checkbox {
        margin-left: auto;
        margin-right: auto;
        width: 80%;

        .sps-custom-control--no-label {
            display: inline-block;
        }
    }
    .sps-form-control {
        padding-left: 5px;
    }
    .login-content-body__description {
        font-size: 14px;
        margin-bottom: 15px;
    }
    .sps-account-finalized {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
        min-height: 444px;
        p {
            margin: 0;
            color: #4b5356;
        }
        img {
            margin-bottom: 5px;
        }
    }
    .sps-password-input__toggle-button {
        /* Correct for a bug in UI React */
        top: 71%;
        right: 3%;
    }
}
