@import "@spscommerce/colors/dist/_colors.scss";

.profile-dropdown {
    .blackbar-image {
        background-color: #717779;
        img {
            display: block;
            width: 45px;
            height: 45px;
        }
    }

    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: #4b5356;
    border-left: 1px solid #000;

    .trigger-company-name {
        display: block;
        background: #4b5356;
        color: #fff;
        min-width: 173px;
        height: 45px;
        border: 0 none;
        line-height: 45px;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        margin: 0 15px;
    }
}

.sign-in-link {
    display: block;
    float: right;
    padding: 12px 40px 10px 0;

    a {
        color: $white;
        font-size: 12px;
        margin-left: 25px;
    }

    a:hover {
        text-decoration: none;
    }
}

.sps-card.dropdown-card {
    width: 340px;
    font-family: "Source Sans Pro";

    .sps-card__footer {
        background-color: #f6f6f6;
        text-align: center;
    }

    .dropdown-image {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $gray500;
        max-width: 90px;
        max-height: 90px;

        img {
            display: block;
            width: 90px;
            height: 90px;
        }
    }

    .col-sm-8 > div {
        margin-bottom: 19px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .name {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
    }

    .email {
        color: $gray500;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
    }

    .controls {
        a {
            font-size: 12px;
            font-weight: 600;
            line-height: 15px;

            .sps-btn i:before {
                font-size: inherit;
            }
        }
    }
}
