#contact-support-form {
  .sps-page {
    padding-top: 1.25rem;
    margin-bottom: 4rem;
  }
}

.action-footer {
  align-items: center;
  background-color: #fff;
  bottom: 0;
  box-shadow: 0 -0.0625rem 0.125rem 0 rgba(0, 0, 0, 0.15);
  display: flex;
  height: 3.125rem;
  justify-content: center;
  left: 0;
  margin-top: 2.125rem;
  width: 100%;

  &--fixed {
    position: fixed;
  }
}

.sps-focused-task {
  background-color: #F6F6F6;
}
