@import "@spscommerce/colors/dist/_colors.scss";

.page-banner {
  margin-top: 105px;
  margin-bottom: 40px;
  background-image: url("./Pre-Auth-Banner@2x.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;

  &.page-banner__show-ie-banner {
    margin-top: 187px;
  }
}

.page-banner__contents {
  margin: 0 auto;
  padding: 30px 0 20px;
}

.page-banner__heading {
  color: $white;
  font-size: 42px;
  font-weight: 300;
}

.page-banner__button-container {
  margin: 0 auto;
  max-width: 336px;

  .sps-btn {
    padding: .3125rem .9375rem;
  }
}

.page-banner__button {
  max-width: 165px;
  color: $white;
  margin: 20px 0 0;
  display: inline-block;
  &:first-child {
    margin-right: 10px;
  }
  &:last-child {
    border: 1px solid $white;
  }
}

.bg-none{
  background: none;
}