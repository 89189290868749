@import "@spscommerce/colors/dist/_colors.scss";
@import "sass-rem/rem";
.schema-download-contain {
    color: #9013fe;
    a {
        color: #9013fe;
        font-weight: 600;
        :hover {
            color: #9013fe;
        }
    }
}
.schema-page-title {
    margin-bottom: rem(20px);
}
.list-heading {
    color: $gray600;
    font-weight: 400;
}

.line-24 {
    line-height: 24px;
}

.document-name--color {
    color: #1f282c;
}

.schema-download__wrapper {
    margin-bottom: 75px;
}
