@import "@spscommerce/colors/dist/_colors.scss";

.wizard-section {
  margin-bottom: 15px;

  .wizard-header {
    background: $white;
    border: 1px solid $gray300;
    border-radius: 0.1875rem;
    height: 65px;
    margin-bottom: -1px;

    .trigger {
      border: 0 none;
      cursor: pointer;
      padding: 0;
      height: 100%;
      width: 100%;

      .container {
        .header {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          margin-left: 20px;

          .number-circle {
            display: flex;
            flex: 0 0 0%;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background-color: #0079a5;
            border: #0079a5;
            border-radius: 100%;
            height: 25px;
            width: 25px;

            .number-text {
              color: $white;
              font-size: 16px;
              font-weight: 600;
              margin-bottom: 10%;
            }

            &-expanded {
              display: flex;
              flex: 0 0 0%;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              background-color: $white;
              border: $white;
              border-radius: 100%;
              height: 25px;
              width: 25px;

              .number-text {
                color: $blue400;
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 10%;
              }
            }
          }

          .message {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            padding-left: 10px;
            padding-top: 2px;

            .message-text {
              color: #606a6e;
              font-size: 16px;
              font-weight: 600;
              margin-bottom: 0;

              &-expanded {
                color: $white;
                font-size: 16px;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
    .trigger:disabled {
      cursor: auto;
    }

    .item-expanded {
      background-color: $blue400;
    }
  }

  .wizard-body {
    .wizard-card {
      margin-bottom: 10px;

      .sps-card__body {
        padding: 0;

        .wizard-app-row {
          display: flex;
          align-items: stretch;
          padding: 0 15px;
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }

          &.radio {
            padding: 0;
          }
        }

        .wizard-app-row .radio {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-right: 1px solid $gray300;
          // padding-left: 30px;
          .sps-custom-control.sps-custom-radio {
            margin-bottom: 0;
            margin-right: 0;
          }
        }

        .wizard-app-row .circle .circle-icon {
          font-size: 16px;
          color: $gray500;
        }

        .wizard-app-row .app-name-container {
          padding: 20px;
        }

        .wizard-app-row .app-name-container .app-name {
          color: $gray600;
          font-size: 14px;
          font-weight: 600;
        }

        .wizard-app-row .app-name-container .app-type-descr {
          color: $gray400;
          font-size: 14px;
          margin: 0;
        }
      }
    }

    .save-button-app-types {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 10px;
    }
  }
}
