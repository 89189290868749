@import "@spscommerce/colors/dist/_colors.scss";
@import "sass-rem/rem";

$spacer: 1.25rem;
.sps-docs-markdown {
    // All markdown styles go here
    h1 {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 1.5rem;
        margin-bottom: $spacer;
    }
    h2 {
        font-weight: 400;
        font-size: 1.375rem;
        line-height: 1.375rem;
        margin-bottom: $spacer;
    }
    h3 {
        font-size: 1.25rem;
        line-height: 1.25rem;
        font-weight: 400;
    }
    h4 {
        font-size: 1.125rem;
        line-height: 1.125rem;
        font-weight: 400;
    }
    h5 {
        font-size: 1rem;
        line-height: 1rem;
        font-weight: 400;
    }
    h6 {
        font-size: 0.875rem;
        line-height: 0.875rem;
        font-weight: 400;
    }
    img {
        display: block;
        max-width: 100%;
        text-align: center;
    }
    blockquote {
        border-left: 3px solid $gray200;
        font-size: 1.25rem;
        padding: ($spacer * 0.25);
        padding-left: ($spacer * 0.5);
    }

    ol {
        margin: 0 0 ($spacer * 0.5) 0;
        padding-left: rem(40px);
        list-style: none;

        &:first-of-type {
            counter-reset: markdown-li;
        }
        li {
            color: $gray600;
            font-size: rem(14px);
            line-height: rem(28px);
            counter-increment: markdown-li;
            &::before {
                content: counter(markdown-li) ".";
                font-weight: 600;
                margin-right: rem(10px);
                margin-left: rem(-20px);
                text-align: right;
            }
            p {
                display: inline;
                margin-left: rem(-4px);
            }
            pre {
                margin-top: rem(16px);
            }
            &:last-child {
                pre {
                    margin-bottom: rem(0px);
                }
            }
        }
    }

    ul {
        margin: 0 0 ($spacer * 0.5) 0;
        padding-left: rem(20px);
        list-style-type: disc;
        & > li {
            font-size: rem(14px);
            line-height: rem(28px);
            margin-left: rem(20px);
            &::before {
                font-weight: bold;
                padding-right: rem(10px);
            }
            p {
                display: inline;
                margin-left: rem(-4px);
            }
            ul {
                list-style-type: disc;
                padding-left: rem(0px);
            }
        }
    }

    p {
        color: $gray600;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    :not(.sps-table-container) > table {
        min-width: 100%;
        border-collapse: collapse;
        border-left: 1px solid $gray300;
        border-right: 1px solid $gray300;
        margin-bottom: $spacer;
        tr {
            border-bottom: 1px solid $gray300;
        }
        td,
        th {
            font-size: 0.875rem;
            padding: ($spacer * 0.5);
            line-height: 1.25rem;
        }
    }
    :not(.sps-table) > thead th {
        background-color: $gray200;
        border-bottom: solid $gray400 2px;
        color: $gray500;
        font-size: 0.875rem;
        cursor: pointer;
        position: relative;
        white-space: nowrap;
    }

    .code-card {
        background-color: #f8f8f8;
        overflow-x: auto;
        .hljs {
            line-height: 1.75;
            padding: 0;
            &.html {
                line-height: 1.6;
            }
        }
    }

    .text-highlight {
        font-family: "Source Code Pro", monospace;
        background-color: $blue100;
        border-radius: rem(3px);
        padding: rem(3.2px);
    }

    del {
        @extend .text-highlight;
        text-decoration: none;
    }

    ol:last-child {
        margin-bottom: 0px;
    }

    .sps-card__body {
        & > ul:last-child,
        & > pre:last-child {
            margin-bottom: 0;
            & ul:last-child,
            & pre:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.sps-docs-container-markdown {
    margin-bottom: 75px;
    .sps-card__body {
        .sps-content-row {
            .sps-content-row__col-group {
                border-radius: rem(3px);
            }
        }

        .sps-content-row--expanded {
            .sps-content-row__col-group {
                border-radius: rem(3px 3px 0 0);
            }
            .sps-content-row__expansion {
                border-radius: rem(0 0 3px 3px);
                padding-top: rem(16px);
                padding-bottom: rem(16px);
                padding-left: rem(16px);

                &.dev-center-ui-response-codes {
                    padding: rem(20px);
                }
            }
        }
    }
}
