@import "@spscommerce/colors/dist/_colors.scss";

.pattern-matcher {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: #4b5356;
    font-size: 12px;
}
.container-pattern {
    display: flex;
    align-items: center;
    width: 50%;
    font-size: 0.6rem;
    padding: 5px 10px 0 0;
    flex-grow: 1;
    line-height: 1;
    color: #717779;

    span {
        font-size: 12px;
        margin-left: 5px;
        margin-bottom: 2px;
    }
}

.password-text {
    color: $gray300;
    &.valid {
        color: $gray500;
    }
}
.password-icons {
    .sps-icon--md {
        font-size: 14px !important;
    }
}
