@import "@spscommerce/colors/dist/_colors.scss";
@import "sass-rem/rem";

$spacer: rem(20px);
.rsx-ui-page {
    .split-card-pattern {
        h4 {
            color: $gray600;
            font-weight: normal;
        }

        h3 {
            font-size: rem(16px);
            line-height: rem(16px);
            font-weight: normal;
        }

        p:last-child {
            margin-bottom: rem(0px);
        }
    }

    h1 {
        color: $gray600;
    }

    h2 {
        color: $gray600;
        font-weight: 600;
        font-size: rem(18px);
        margin-bottom: rem(10px);
    }

    h3 {
        color: $gray600;
        font-size: rem(14px);
        font-weight: 600;
        line-height: rem(20px);
    }

    h4 {
        color: $gray600;
        font-weight: 300;
        font-size: rem(18px);
    }

    h5 {
        color: $gray600;
    }

    h6 {
        color: $gray600;
    }

    ul {
        margin: 0 0 ($spacer * 0.5) 0;
        padding-left: rem(20px);
        & > li {
            font-size: rem(14px);
            line-height: rem(28px);
            margin-left: rem(20px);
            &::before {
                font-weight: bold;
                padding-right: rem(10px);
            }
            p {
                display: inline;
                margin-left: rem(-4px);
            }
            ul {
                list-style-type: disc;
                padding-left: rem(0px);
            }
        }
    }

    .markdown-image__wrapper {
        margin-bottom: rem(20px);
        text-align: center;

        img {
            display: inline;
        }
    }

    #preview {
        min-height: rem(600px);
        p:last-child > a {
            color: $blue400;
            text-decoration: none;
            font-weight: 600;
        }
    }

    .steps-icon {
        background-color: $purple100;
        border: rem(1px solid $purple200);
        border-radius: 50%;
        color: $purple200;
        float: left;
        font-size: rem(20px);
        height: rem(40px);
        line-height: rem(40px);
        text-align: center;
        width: rem(40px);
    }

    .text-highlight {
        font-family: "Source Code Pro", monospace;
        background-color: $blue100;
        border-radius: rem(3px);
        padding: rem(3.2px);
    }

    del {
        @extend .text-highlight;
        text-decoration: none;
    }
}

.rsx-side-nav {
    min-height: rem(420px);

    .sps-side-nav__section-content {
        a.sps-side-nav__link {
            color: $gray600;
            i {
                font-size: rem(10px);
                line-height: rem(20px);
                top: rem(5px);
            }
        }

        a.sps-side-nav__link.active {
            font-weight: 600;
            color: $gray600;
            border-left: rem(2px solid $blue200);
        }

        a.active {
            padding-left: rem(28px);
            i {
                left: rem(13px);
            }
        }

        li {
            a {
                padding-left: rem(45px);
            }
            .active {
                padding-left: rem(43px);
                i {
                    padding-left: rem(13px);
                }
            }
            i {
                padding-left: rem(15px);
            }
        }

        ul {
            ul {
                a {
                    padding-left: rem(60px);
                }
                a.active {
                    padding-left: rem(58px);
                }
            }
        }
    }
}

.rsx-side-nav {
    .section-seprator {
        border-top: rem(1px) solid $gray300;
        margin-top: rem(15px);
    }

    .section-heading {
        margin-left: rem(10px);
        color: $gray500;
        line-height: rem(16px);
        text-transform: none;
    }
}
