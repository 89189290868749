@import '@spscommerce/colors/dist/_colors.scss';

.app-card-container-bottom {
  border-top: 1px solid $gray300;
  height: 100%;
  .icon-card--small & {
    border: none;
  }
}

.app-card-container-bottom {
  padding: 15px 15px 60px;
  position: relative;
  .icon-card--small & {
    align-self: center;
    padding: 0 15px;
    width: 70%;
  }
}
.app-card-container-links {
  position: absolute;
  bottom: 25px;
  right: 9px;
  left: 20px;
}

.app-card-title {
  font-weight: 400;
  font-size: 14px;
  color: $blue400;
  float: left;
  width: 95%;
  word-wrap: break-word;
  white-space: normal;
  .icon-card--small & {
    font-weight: 600;
    font-size: 18px;
    color: $gray600;
    line-height: 1.25;
    margin-bottom: 10px;
  }
}

.app-card-view-details-link {
  float: left;
  font-size: 16px;
  margin-top: 10px;
  width: 5%;
}

.app-card-view-details-link a {
  margin-right: 10px;
}

.app-card-view-details-link a {
  color: $gray400;
}

.app-card-view-details-link a:hover {
  border: none;
  text-decoration: none;
  color: $blue400;
}

.app-card-view-details-dropdown {
  float: right;
  margin-top: 5px;
}

.app-card-container-app-description {
  .icon-card--small & {
    font-size: 14px;
    font-weight: 600;
    color: $gray500;
    white-space: normal;
    line-height: 1.25;
  }
}

.app-card-container-services {
  margin-top: 10px;
}

.app-card-service-heading {
  float: left;
  width: 30%;
}

.app-card-service-heading span {
  color: $gray400;
  font-weight: 600;
  text-transform: uppercase;
}

.app-card-services {
  float: right;
  width: 70%;
}

.app-card-services div {
  text-align: right;
  color: $gray500;
  font-size: 12px;
  font-weight: 500;
}

.gradient {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  width: 90%;
}

.gradient:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 15px;
  background: linear-gradient( to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}
