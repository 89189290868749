@import "@spscommerce/colors/dist/_colors.scss";
.black-bar {
    background: #1f282c; // was $gray600 but that is used for profile dropdown
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 1040;
    height: 45px;
    // grid stuff
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 420px 1fr max-content;
    grid-template-columns: 420px 1fr max-content;
    grid-template-areas: "left . right";
    .logo-container {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        grid-area: left;
        display: flex;
        justify-content: flex-start;
    }

    .dropdown-container {
        -ms-grid-row: 1;
        -ms-grid-column: 3;
        grid-area: right;
    }
    h1.logo {
        background-image: url("./sps-logo-black.svg");
        background-repeat: no-repeat;
        width: 420px;
        height: 45px;
        line-height: 45px;
        font-size: 1.25em;
        text-indent: -9999px;
        background-size: 363px 45px;
        -ms-flex-item-align: start;
        align-self: flex-start;
        a {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    &.black-bar__show-ie-banner {
        top: 82px;
    }
}
