// stoplight import must go first so sps style can override general tags and fonts
@import "@stoplight/elements-dev-portal/styles.min.css";
@import "@spscommerce/ds-styles/index.css";
@import "@spscommerce/colors/dist/_colors.scss";

// TODO: Remove these overrides once we have updated to ds-react components
// the not(.sps-form-group) ensures that the ds-react components don't inherit the styles
.sps-text-input:not(.sps-form-group) {
    .sps-form-control {
        padding: 0.3125rem 0.5rem;
        margin-bottom: 10px;
    }
    // This targets only the input fields with labels :fingerscrossed:
    label + .sps-form-control__clear-btn {
        /* Correct for a bug in UI-React  */
        margin-top: 1.65rem;
    }
}
.sps-page {
    padding-top: 90px;
    &.nav-hidden {
        padding-top: 0;
    }
    &.collapse-top {
        padding-top: 0;
        margin-top: 2.8125rem;
    }
    &.sps-page__show-ie-banner {
        padding-top: 172px;

        .sps-navbar-container {
            top: 127px;
        }

        .sps-side-nav {
            --space: 14.175rem;
        }
    }
}

.page-banner + .sps-page {
    &.collapse-top {
        margin-top: 1.25rem;
        min-height: calc(100vh - 378px);
    }

    &.sps-page__show-ie-banner {
        padding-top: 0;
    }
}

.all-user-banner + .page-banner {
    margin-top: 140px;

    &.page-banner__show-ie-banner {
        margin-top: 222px;
    }
}

.all-user-banner + .sps-page {
    margin-top: 5.313rem;
}

.trial-user-banner + .page-height {
    margin-top: 5.313rem;
}

.sps-page-wrapper {
    width: 100%;
}
.sps-card__body--collapse {
    padding: 0;
}
.api-key-table-cell {
    padding: 20px;
    border-right: 1px solid #d2d4d5;
}
.api-key-table-cell-header {
    color: #a5a9ab;
    font-weight: 600;
}
.sps-card__body--collapse .col:last-child {
    .api-key-table-cell {
        border: 0;
    }
}

.user-agreement-page-heading {
    font-weight: 300;
    font-size: 24px;
    margin-bottom: 20px;
}

// Expiremental SPS Styles
.dev-center-docs__list {
    list-style: none;
    padding-left: 10px;
}

.sps-card {
    &.sps-card--collpased {
        .sps-card__body {
            padding: 0;
        }
    }
}

.sps-zero-state__image {
    margin: 0 auto 0.9375rem;
}

// Changes to accomadate blackbar
.sps-navbar-container {
    top: 45px;
    z-index: 1030;
}

.schema-download-placeholder-container {
    padding: 20px;
}

.page-height {
    min-height: calc(100vh - 113px);
    margin: {
        bottom: 0rem;
        top: 2.8125rem;
    }
}

// remove below style when components from ui-react are replaced by ds-react.
button.sps-btn {
    padding: 0.3125rem 0.9375rem;
    &.sps-btn--link {
        padding: 0.3125rem 0;
    }
    &.sps-btn--icon {
        padding: 0.3125rem 0.625rem;
    }
}

.sps-dropdown,
.sps-select {
    .sps-option-list {
        z-index: 1;
    }
}

.sps-growler-area {
    z-index: 10000;
}
