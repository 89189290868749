.tos-page {
  .sps-page {
    margin-top: 0;
    padding-top: 52px;
    text-align: left;
  }
  .sps-focused-task__title {
    display: none;
  }
  &-logo {
    width: 100%;
    text-align: center;
    margin-bottom: 2.125rem;

    img {
      height: 85px;
      width: 300px;
      margin: auto;
    }
  }
  p {
    color: #62686b;
    font-family: Helvetica;
    font-size: 16px;
    line-height: 19px;
  }
  &-checkbox {
    display: flex;
    justify-content: center;
    margin: 30px 0 80px 0;
  }
  &-heading {
    font-weight: bold;
  }
  &-agree {
    .sps-custom-control__label {
      color: #62686b;
      font-family: Helvetica;
      font-size: 14px;
      font-weight: bold;
      line-height: 16px;
    }
  }
}
