@import "@spscommerce/colors/dist/_colors.scss";

.sps-wallpaper {
    background-image: url("https://cdn.prod.spsc.io/web/framework/assets/18.03.02/images/sps-icon-background@2x.png");
    background-size: 200px 200px;
    background-repeat: repeat;
    background-color: #f6f6f6;
}

.sps-login-card-supply-pike {
    margin-top: 80px;
    min-height: 453px;
    position: relative;
    .sps-card__body {
        display: flex;
        width: 780px;
        padding: 0px;
    }
}

.sps-login-card-supply-pike__top-shelf {
    position: absolute;
    bottom: 100%;
    padding: 20px 0;
}

.sps-login-card-supply-pike__col {
    text-align: center;
    align-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.sps-login-card-supply-pike__col--left {
    background: #007db8;
    width: 42%;
    min-height: 250px;
    border-radius: 0.1875rem 0 0 0.1875rem;

    img {
        max-width: 211px;
        height: auto;
    }
}

.sps-login-card-supply-pike__col--right {
    width: 58%;
    border-radius: 0 0.1875rem 0.1875rem 0;
    padding: 50px 0;
}

.login-content-body-supply-pike {
    text-align: left;
    width: 293px;
    min-height: 250px;
    .supply-pike__label {
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 5px;
    }
    .supply-pike__checkbox {
        display: flex;
        color: #1f282c;
        flex-direction: row;
        font-size: 12px;
        line-height: 16px;
        margin-left: 1px;
        margin-bottom: 5px;
        .sps-custom-control__input {
            margin-top: 10px;
        }
        p {
            margin-top: 8px;
            font-size: 12px;
            line-height: 16px;
        }
        a {
            margin-top: 8px;
            font-weight: 600;
        }
    }
    .supply-pike__btn {
        display: flex;
        justify-content: space-between;
        .sps-btn {
            width: 143px;
            button {
                width: 143px;
            }
        }
    }
}
