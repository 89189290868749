.start-choosing__title {
    font-size: 0.875rem;
    font-weight: 600;	
    line-height: 1.125rem;
    color: #1f282c;
}

.qualifier-list-select {
    position: absolute;
    right: 1rem;
    top: 1.25rem;
    width: 12.5rem;

    .sps-select {
        width: 100%;
    }

    .sps-option-list {
        width: inherit;
    }
}

.documents-list-container {
    margin-top:1.25rem;
}

