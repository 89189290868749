@import "@spscommerce/colors/dist/_colors.scss";

.sps-file-upload {
    padding: 1.25rem 0.7rem;

    &__content {
        width: auto;
    }
}
.dropzone {
    align-items: center;
    background-color: #fafafa;
    border-color: #eeeeee;
    border-radius: 2px;
    border-style: dashed;
    border-width: 2px;
    color: #bdbdbd;
    display: flex;
    flex: 1;
    flex-direction: column;
    outline: none;
    text-align: center;
    transition: border 0.24s ease-in-out;

    &__help-text {
        color: $gray500;
        font-size: 12px;
    }

    &__heading {
        display: none;
    }

    .sps-zero-state--micro {
        padding: 20px 20px 5px 20px;
    }
}

.cropper-container {
    .cropper-drag-box {
        opacity: 0.7;
    }
    .cropper-crop-box {
        .cropper-line,
        .cropper-point {
            background-color: $white;
        }

        .cropper-view-box {
            outline: 2px solid $white;
            outline-color: $white;
        }
    }
}
