@import "sass-rem/rem";
@import "@spscommerce/colors/dist/_colors.scss";

.rsx-landing-page {
    font-size: rem(14px);
    line-height: rem(20px);
    min-height: calc(100vh - var(--offset) * 2);
    --offset: var(--space);
    margin-bottom: 75px;
    margin-left: auto;
    margin-right: auto;
    h2 {
        font-size: rem(24px);
        line-height: rem(24px);
    }

    ul {
        padding-inline-start: 40px;
        margin-left: rem(-23px);
        list-style: none;
        & > li {
            padding-left: 5px;
            &::before {
                content: "\2022";
                color: $gray400;
                font-weight: bold;
                display: inline-block;
                font-size: rem(20px);
                width: rem(20px);
                margin-left: rem(-20px);
            }
        }
    }
}
