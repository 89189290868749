@import '@spscommerce/colors/dist/_colors.scss';

.app-side-bar {
  .sps-card__header {
    padding: 0;
    position: relative;
    height: 65px;
    background-image: url("./icon-pattern@2x.png");
    background-size: 75%;
    background-color: $blue400;
    .app-side-bar-icon {
      position: absolute;
      top: 100%;
      left: 50%;
      width: 62px;
      height: 62px;
      text-align: center;
      transform: translate(-50%, -50%);
      font-size: 30px;
      padding: 15px;
      border-radius: 100%;
      border: 1px solid $gray300;
      background: $white;
      color: $orange200;
    }
  }
  .sps-card__body.has-header {
    padding: 25px 0 0 0;
  }
}

.app-edit-header {
  margin-bottom: 20px;
}

.app-edit-save-btn {
  float: right;
}

.app-side-bar__bordered-list-item {
  border-bottom: 1px solid $gray200;
  padding: 1.25em;
  &:last-child {
    border: 0;
  }
  .bordered-list-item__header {
    font-size: 16px;
  }
  .bordered-list-item__body {
    .app-side-bar__text {
      font-weight: 600;
      &.app-side-bar__delete_link {
        .sps-btn--link {
          color: $red200;
          font-weight: 1000;
        } 
      }
    }
    
  }
}
