@import '@spscommerce/colors/dist/_colors.scss';

.inline-service-card {
  .sps-card__header {
  font-weight: 600;
  font-size: 12px;
  }
  .sps-card.sps-card--collpased {
    .sps-card__body {
      padding: 15px 0;
    }
    &.app-edit-services {
      .sps-card__body {
        padding: 0;
      }
    }
  }
}

.service-row {
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  &.toggle {
    justify-content: space-between;
    border-bottom: 1px solid $gray300;
    padding: 15px;
    margin: 0;
    .service-link {
      width: 80%;
      margin-left: 0;
    }
  }
  .sps-toggle {
    float: right;
  }
}

.service-row .circle {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $gray200;
  border-radius: 100%;
  height: 40px;
  width: 40px;
}

.service-row .circle .circle-icon {
  font-size: 16px;
  color: $gray500;
}

.service-row .service-link {
  margin-left: 15px;
}

.service-row .service-link .service-name a{
  color: $blue400;
  font-size: 14px;
  font-weight: 600;
}
.service-row .service-link .service-name a:hover{
  text-decoration: none;
  color: $orange300;
}

.service-row .service-link .service-descr {
  color: $gray400;
  font-size: 14px;
  margin: 0;
}
