@import "@spscommerce/colors/dist/_colors.scss";
.profile-organization-selector {
  width: 100%;
  margin-top: 20px;
  position: relative;
}
.password-field {
  margin-top: 15px;
  & .sps-password-input__toggle-button {
    top: 70%;
  }
}
.sps-spinner {
  margin: 0 auto;
}
