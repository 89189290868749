@import '@spscommerce/colors/dist/_colors.scss';

.connection-informations {
  display: flex;
  flex-direction: column;
  &.has-secret {
    width: 100%;

    .connection-information__app-id {
      border-bottom: 0;
      border-radius: .1875rem .1875rem 0 0;
    }
    .connection-information__app-secret {
      border-radius: 0 0 .1875rem .1875rem;
      flex: 1;
    }
    .connection-information__container-copy {
      flex: .5;
    }
  }
}
.connection-information {
  margin: 0 auto;
  width: 100%;
  &.connection-information__button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
  }
}

.connection-information-title {
  font-weight: 600;
  font-size: 14px;
}

.connection-information__title {
  font-weight: 600;
  text-transform: uppercase;
  color: $gray500;
  margin-bottom: 15px;
}
.connection-information__content{
  display: flex;
  flex-direction: row;
}

  .connection-information__container-input {
    min-width: 81%;
    flex: 2 0;
    color: $gray600;
    word-break: break-all;
    padding-right: 20px;
  }

  .connection-information__container-visibility {
    font-size: 16px;
    color: $gray500;
  }

  .connection-information__grey-next-box {
    margin-top: 20px;
  }
