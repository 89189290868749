@import "@spscommerce/colors/dist/_colors.scss";
@import "sass-rem/rem";

$gray-transparent: rgba(246, 246, 246, 1);
$scroll-shadow-color: rgba(0, 0, 0, 0.15);

pre.dcui-code-snippet {
    height: 100%;
    min-height: rem(40px);
    width: 100%;
    tab-size: 2;
    background: {
        attachment: local, local, scroll, scroll;
        image: linear-gradient(to right, $gray100, $gray-transparent),
            linear-gradient(to left, $gray100, $gray-transparent),
            linear-gradient(to right, $scroll-shadow-color, $gray-transparent),
            linear-gradient(to left, $scroll-shadow-color, $gray-transparent);
        position: 0 0, 100% 0, 0 0, 100% 0;
        repeat: no-repeat;
        size: rem(50px) 100%, rem(50px) 100%, rem(10px) 100%, rem(10px) 100%;
    }
    display: block;
    overflow-x: auto;
}
