.stoplight-nav-row {
    width: 100%;
    display: block;
}

.stoplight-block {
    width: 1600px;
    position: relative;
}

.stoplight-block > .stoplight-search {
    margin-left: 0;
    margin-right: 0;
}

#mosaic-provider-react-aria-0-1 {
    width: 100%;
    font-family: Source Sans Pro,Helvetica,Arial,sans-serif;
}

// left column nav updates
.sl-elements .sl-elements-api > div.sl-flex{
    margin-right: 10px;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #d2d4d5;
    border-radius: 0.1875rem;
    padding-top: 0px;
}

.sl-elements .sl-elements-api > div.sl-flex .sl-bg-canvas-100 {
    background-color: #fff;
}

.sl-elements .sl-elements-api > div.sl-flex .sl-uppercase.sl-text-sm.sl-leading-relaxed.sl-tracking-wide {
   color: #a5a9ab;
   font-size: 1rem;
   font-weight: 300;
   margin: .9375rem 0 .5rem 1.25rem;
   font-family: Source Sans Pro,Helvetica,Arial,sans-serif;
}

.sl-elements .sl-elements-api > div.sl-flex .sl-items-center.sl-truncate {
    color: #62686b;
    font-weight: 500;
    font-family: Source Sans Pro,Helvetica,Arial,sans-serif;
 }

// center column updates
.sl-elements .sl-elements-api > div.sl-bg-canvas {
    margin-left: 10px;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #d2d4d5;
    padding: 1.25rem;
}

.sl-elements .sl-elements-api div.sl-bg-canvas > div.sl-py-16 {
    padding: 10px;
}

.sl-elements .sl-elements-api div.sl-bg-canvas .sl-text-heading {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
    font-family: Source Sans Pro,Helvetica,Arial,sans-serif;
}

.sl-elements .sl-elements-api div.sl-bg-canvas p {
    color: #1f282c;
    font-size: .875rem;
    line-height: 1.25rem;
    font-family: Source Sans Pro,Helvetica,Arial,sans-serif;
}
