@import '@spscommerce/colors/dist/_colors.scss';

.sps-form-group__password-input {
  position: relative;
}
.sps-password-input__toggle-button{
  position: absolute;
  right: 5%;
  top: 50% !important;
  transform: translateY(-50%);
  font-size: 16px;
  color: $gray500;
  cursor: pointer;
}
