.doc-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .icon-card {
        max-width: 280px;
        &:nth-child(3n + 0) {
            margin-left: 20px;
        }
        &:first-child,
        &:nth-child(4n + 0) {
            margin-right: 20px;
        }
    }
}
.doc-grid-content {
    .doc-grid {
        margin-bottom: 75px;
    }
}

.docs-main-content {
    margin-top: 0.625rem;
    .sps-footer {
        position: absolute;
        bottom: 0;
        padding-right: 0.625rem;
        padding-left: 0.625rem;
    }
}

.swagger-docs {
    margin-bottom: 75px;
}

.stoplight-search {
    margin-left: 10px;
    margin-right: 10px;
}
