@import '@spscommerce/colors/dist/_colors.scss';

.service-card-title {
  font-weight: 600;
  font-size: 16px;
  color: $blue400;
  padding: 0 15px 15px;
  word-wrap: break-word;
  white-space: normal;
}
