.template-dropdown {
  display: inline-block;
  position: relative;
}
.trigger {
  background: transparent;
  border: 0 none;
  padding: 0;
}
.dropdown-content {
  position: absolute;
  top: 100%;
  right: 0;
  left: auto;
}
