.qualifier-link {
    cursor: pointer;
    .sps-card {
        margin-bottom: 1.25rem;
    }
    .sps-card:hover {
        border: 0.0625rem solid #0077AA;
    }
    .qualifier-link--text {
        color: #0077AA;
        font-family: "Source Sans Pro";
        font-size: 1.125rem;
    }
    .qualifier-link--description {
        color: #62686B;
        font-family: "Source Sans Pro";
        font-size: 0.875rem;
        font-weight: 600;
    }
}
