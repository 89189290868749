.home-page-connect-card {
  min-height: 278px;
  margin-bottom: 1.5rem;
  &__heading {
    font-size: 1.375rem;
    font-weight: 300;
    line-height: 1.75rem;
  }
}
.connection-panel {
  align-self: flex-end;
}
.connection-panel-image {
  margin: 1.125rem 0.5rem;
}
.connection-panel-title {
  font-size: 1.125rem;
  line-height: 1.4375rem;
  font-weight: 400;
}
.connection-panel-link {
  margin-bottom: 0;
  a {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
  }
}
