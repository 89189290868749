.login-content-body__header.login-content-body__header--signup {
    font-size: 24px;
    margin-bottom: 20px;
}
.recaptcha-container {
    margin-top: 20px;
}

.sign-up__body.login-content-body {
    width: 302px;
    .sps-text-input .sps-form-control {
        margin-bottom: 10px;
    }
}

.signup-page__container-submit-btn {
    text-align: center;
    margin-top: 20px;

    button {
        width: inherit;
    }
}

.signup-page__container-submit-btn-ul {
    text-align: center;
    margin-top: 20px;
    width: 100%;

    .disabled.sps-btn.sps-btn--confirm {
        background-color: transparent;
    }
    .disabled.sps-btn.sps-btn--confirm:hover {
        background-color: transparent;
    }

    .sps-btn.sps-btn--confirm {
        background-color: #007dbe;
    }
    .sps-btn--confirm:hover {
        background-color: #09638d;
    }

    button {
        width: inherit;
        padding: 16px 0 !important;
    }
}

.signup-page__container-sign-in {
    margin-top: 20px;
    text-align: center;
}

.signup-page__nav-link {
    font-weight: 600;
    &.signup-page__nav-link--icon {
        display: flex;
        span {
            margin-left: 5px;
        }
    }
}

.signup-complete-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    min-height: 607px;
    p {
        color: #4b5356;
    }
    img {
        margin-top: 20px;
        margin-bottom: 5px;
    }
}

.signup-page__signin--link.sps-btn {
    color: #1f282c;
    text-decoration: underline;
    font-weight: 400;

    &:hover {
        color: #1f282c;
        text-decoration: underline;
    }

    button,
    button:focus {
        padding: 0;
        border: none;
    }
}
.create-trial-account {
    min-height: 651px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.sps-form-control {
    margin-bottom: 10px;
}

.sign-up__card {
    margin-top: 0;
    position: relative;
}

.sign-up__wrapper {
    // the styles applied by .sps-page are incredibly weird
    // this makes the sign up page container take up the full height of the screen, so that it is centered vertically
    min-height: calc(100vh - 113px);
    display: flex;
    justify-content: center;
    align-items: center;
}
