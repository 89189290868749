.sps-truncate-ellipses {
  &:after {
    content: "\2026";
    padding-left: 2px;
  }
}

//TODO: support gradients
// .sps-truncate-gradient {
//   display: block;
//   white-space: nowrap;
//   overflow: hidden;
//   position: relative;
//   width: 90%;
//   padding-right: 5px;
//   &:after {
//     background: linear-gradient(
//       to right,
//       rgba(235, 236, 237, 0),
//       rgba(235, 236, 237, 1)
//     );
//   }
// }
