@import '@spscommerce/colors/dist/_colors.scss';

.tool-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .icon-card {
    max-width: 280px;

    &:nth-child(3n + 0) {
      margin-left: 20px;
    }

    &:first-child,
    &:nth-child(4n + 0) {
      margin-right: 20px;
    }
  }
}

.tools-body__sidebar {
  margin-top: .625rem;
}

.tools-main-content {
  margin-top: .625rem;

  .tool-spinner {
    display: flex;
  }
}

.tool-card-title {
  font-weight: 600;
  font-size: 16px;
  color: $blue400;
  padding: 0 15px 15px;
  word-wrap: break-word;
  white-space: normal;
}
