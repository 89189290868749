@import "@spscommerce/colors/dist/_colors.scss";

.tools-nav-card {
  .tool-row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    & .circle {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $gray200;
      border-radius: 100%;
      height: 40px;
      width: 40px;
      & .circle-icon {
        font-size: 16px;
        color: $gray500;
      }
    }
    .tool-link {
      margin-left: 15px;
      a {
        color: $blue400;
        font-size: 14px;
        font-weight: 600;
        &:hover {
          text-decoration: none;
          color: $orange300;
        }
      }
      .tool-descr {
        color: $gray400;
        font-size: 14px;
        margin: 0;
      }
    }
  }
}
