@import "@spscommerce/colors/dist/_colors.scss";

.icon-card {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  max-width: 254px;
  width: 100%;
  border: $gray300 solid 1px;
  border-radius: 3px;
  background-color: #fff;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 20px;
  color: $blue200;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  &.icon-card--small {
    padding: 12px 1.25rem;
    max-width: 100%;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 0;
  }
  /* colors */
  .blue200 {
    color: $blue200;
  }
  .blue400 {
    color: $blue400;
  }
  .purple200 {
    color: $purple200;
  }
  .orange200 {
    color: $orange200;
  }
  .gray500 {
    color: $gray500;
  }
  .green300 {
    color: $green300;
  }
}

.icon-card-link {
  display: block;
  &:hover {
    text-decoration: none;
  }
}

.icon-card-container-icon {
  .icon-card--small & {
    width: 30%;
  }
}

.inner {
  margin: 15px;
  height: 130px;
  background-color: $gray200;
  padding-top: 10px;
  .icon-card--small & {
    height: auto;
    background: $white;
    margin: 0;
    padding: 0;
  }
}
.inner .circle {
  display: block;
  margin: 0 auto;
  height: 110px;
  width: 110px;
  border-radius: 100%;
  background-color: $white;
  padding: 15px;
  position: relative;
  .icon-card--small & {
    width: 90px;
    height: 90px;
    background-color: $gray200;
  }
}
.inner .circle .circle-image {
  max-height: 100%;
  max-width: 100%;
}

.inner .circle .circle-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 65px;
  display: inline-block;
  line-height: 80px;
  margin-left: 1px;
  .icon-card--small & {
    font-size: 55px;
      line-height: 1;
  }
}
.circle-icon.fa-television {
  margin-left: 3px;
}
