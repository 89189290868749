.schema-document {
  .schema-document--loader {
    text-align: center;
  }
  &--card {
    margin-bottom: 1.25rem;
  }
  .section-title {
    display: flex;
    margin-bottom: 0.625rem;
    align-items: center;
    .section-parent {
      font-size: 0.9375rem;
      button {
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 1.5rem;
        // padding-top: 0;
        &:focus {
          outline: none;
          text-decoration: none;
        }
      }
    }
    .section-icon {
      font-weight: 800;
      font-size: 1.125rem;
    }
    .current-section {
      font-size: 1.125rem;
    }
    .conditional-description {
      margin: 0.625rem 0rem;
      font-size: 0.875rem;
    }
  }
  .definition {
    line-height: 1.563rem;
  }
  a {
    text-decoration: none;
  }
  .sps-card .sps-description-list + .section-title {
    margin-top: 1.25rem;
  }
  .sps-description-list__term {
    .label-tool-type-info {
      font-family: 'Source Code Pro', monospace;
      background-color: #d9f0fa;
      border-radius: 3px;
      width: auto;
      margin-bottom: 0.3rem;
      display: table;
      padding: 0.2rem;
      line-height: 20px;
    }
    button {
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.25rem;
      padding-top: 0;
      padding-bottom: 0;
      &:focus {
        outline: none;
        text-decoration: none;
      }
    }
  }
  .sps-badge.sps-badge--info {
    margin-top: 0.3rem;
    text-transform: capitalize;
  }
}
