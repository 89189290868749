.stoplight-search {
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #d2d4d5;
    border-radius: 0.1875rem;
    margin-bottom: 10px;
    width: 100%;
}

.stoplight-search {
    div.sl-bg-transparent > div {
        z-index: 1;
    }
}

.stoplight-search > input {
    width: 100%;
    padding: 10px;
    color: "white";
}
